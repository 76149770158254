import axios from 'axios';

export const isBrowser = () => typeof window !== "undefined"
export const getUser = () => isBrowser() && window.localStorage.getItem("aruzyuser") ? JSON.parse(window.localStorage.getItem("aruzyuser")) : {}
const setUser = user => window.localStorage.setItem("aruzyuser", JSON.stringify(user))
const setSubscription = user => window.localStorage.setItem("aruzysubscription", JSON.stringify(user))

export const handleLogin = async ({email, password}) => {
    try {
        var result = await axios.post(`${process.env.API_URL}/auth/local`, {
            identifier: email,
            password: password
        })
        /*.then(res => {
                    console.log("Res: ", res)
                    return setUser({
                      //name: `Johnny`,
                      jwt: res.data.jwt,
                      email: res.data.user.email
                    })
                }).catch(err => {
                    return false
                });*/
    } catch (e) {
        return false
    }

    //Anything other than 200 is failed login
    if (result.status !== 200) {
        return false
    }

    if (result.status === 200 && result.data?.jwt) {
        return setUser({
            //name: `Johnny`,
            jwt: result.data.jwt,
            email: result.data.user.email,
            fname: result.data.user.fname,
            lname: result.data.user.lname,
            address1: result.data.user.address1,
            address2: result.data.user.address2,
            city: result.data.user.city,
            county: result.data.user.county,
            is_subscribed: result.data.user.is_subscribed,
            afterduecancel: result.data.user.afterduecancel,
            postcode: result.data.user.postcode,
            mobile: result.data.user.mobile,
            id: result.data.user.id,
            uid: result.data.user.guid,
            image: result.data.user.image,
        });
    }
}

export const loginPath = () => {
    return "/login"
}

export const dashboardPath = () => {
    return "/account"
}

export const isLoggedIn = () => {
    const user = getUser()
    return !!user.jwt
}

export const saerchpath = (data) => {
    return "/search?q=" + data;
}
export const logout = callback => {
    setUser({})
    callback()
}

export const BrandLogin = async ({email, password}) => {
    try {
        var result = await axios.post(`${process.env.API_URL}/login-brand`, {
            identifier: email,
            password: password
        }).then({}).catch(error => {
            console.log(error);
        })
    } catch (e) {
        console.log(e);
        return false
    }
    if (result.data.statusCode === 200) {
        if (typeof window !== "undefined") {
            window.localStorage.setItem('redirectUrl', '/brand-home');
        }
        return setUser({
            id: result.data.id,
            email: result.data.brand.email,
            image: result.data.brand.image,
            name: result.data.brand.name,
            brand: "brand"
        });
    } else {
        return false
    }

}

export const isBrandLoggedin = () => {
    const user = getUser()
    return !!user.brand
}

export const forgetPassword = async ({email, password}) => {
    try {
        var result = await axios.post(`${process.env.API_URL}/auth/forgot-password`, {
            email: email,
        })
    } catch (e) {
        return false
    }

    //Anything other than 200 is failed login
    if (result.status !== 200) {
        return false
    }

    if (result.status === 200) {
        return true

    }
}

export const BrandforgetPassword = async ({email}) => {

    try {
        var result = await axios.post(`${process.env.API_URL}/brand-forget-password`, {
            email: email,
        })
    } catch (e) {
        return false
    }

    //Anything other than 200 is failed login
    if (result.status !== 200) {
        return false
    }

    if (result.status === 200) {
        return true
    }
}

export const resetPass = async ({code, password, passwordConfirmation}) => {

    try {
        var result = await axios.post(`${process.env.API_URL}/auth/reset-password`, {
            code: code,
            password: password,
            passwordConfirmation: passwordConfirmation
        })
    } catch (e) {
        return false
    }

    if (result.status !== 200) {
        return false
    }

    if (result.status === 200) {
        return true
    }
}

export const getUserSubscription = async () => {
    try {
        const isBrowser = typeof window !== "undefined";
        const aruzyuser = isBrowser ? localStorage.getItem('aruzyuser') : {};
        let aruzyuser1 = isBrowser && window.localStorage.getItem('aruzyuser') !== null ? JSON.parse(aruzyuser) : {};

        await axios.post(`${process.env.API_URL}/getusersubscription`, {
            id: aruzyuser1.id
        }, {
            headers: {
                "Authorization": "Bearer " + aruzyuser1.jwt
            }
        }).then((res) => {
            if (res.data.data.subscription != null) {
                setSubscription(res.data.data)
            }
        })
    } catch (e) {
        console.log(e);
    }
}

export const resetPassBrand = async ({code, password, passwordConfirmation}) => {
    try {
        var result = await axios.post(`${process.env.API_URL}/resetbrandpassword`, {
            code: code,
            password: password,
            passwordConfirmation: passwordConfirmation
        })
    } catch (e) {
        return false
    }
    //Anything other than 200 is failed login
    if (result.status !== 200) {
        return false
    }

    if (result.status === 200) {
        return true
    }
}


