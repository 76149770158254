export const configData = {
    company: {
        name: "Aruzy",
        baseURL: `${process.env.GATSBY_ROOT_URL}`,
        email: "hello@aruzy.co.uk",
        partnerEmail: "partner@aruzy.co.uk",
        social: {
            facebook: "https://www.facebook.com/Aruzy-UK-113596747627626",
            instagram: "https://www.instagram.com/aruzyuk",
            tiktok: "https://www.tiktok.com/@aruzyuk",
            twitter: "https://mobile.twitter.com/aruzyuk"
        }
    }
};