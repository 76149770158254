import * as React from 'react'
import logo from '../images/logo_white.png'
import Link from 'gatsby-link'
import {StaticImage} from "gatsby-plugin-image";
import {configData} from "../services/config";

const Footer = () => {
    return (
        <footer style={{padding: '23px 0'}}>
            <div className='container-fluid'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className="col-md-12 mobiledisplay desktopnone mb-5">
                                    <img src={logo} alt='Aruzy'/>
                                </div>
                                <div className='col-6 col-sm-6 col-md-3 mobilenone'>
                                    <ul style={{color: 'white'}}>
                                        <li>
                                            <Link to='/category/restaurants'>Restaurants</Link>
                                        </li>
                                        <li>
                                            <Link to='/category/experiences'>Experiences</Link>
                                        </li>
                                        <li>
                                            <Link to='/category/retail'>Retail</Link>
                                        </li>
                                        <li>
                                            <Link to='/category/services'>Services</Link>
                                        </li>
                                        <li>
                                            <Link to='/category/events'>Events</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-5 col-sm-5 col-md-3'>
                                    <ul style={{color: 'white'}}>
                                        <li>
                                            <Link to='/about-us'>About Us</Link>
                                        </li>
                                        <li>
                                            <Link to='/contact-us'>Contact Us</Link>
                                        </li>
                                        <li>
                                            <Link to='/partner-with-aruzy'>Partner with Aruzy</Link>
                                        </li>
                                        <li>
                                            <Link to='/earn-with-aruzy'>Earn with Aruzy</Link>
                                        </li>
                                        <li>
                                            <Link to='/blog'>Blog</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-5 col-sm-5 col-md-3'>
                                    <ul style={{color: 'white'}}>
                                        <li>
                                            <Link to='/terms-and-condition'>Terms & conditions</Link>
                                        </li>
                                        <li>
                                            <Link to='/privacy-policy'>Privacy Policy</Link>
                                        </li>
                                        <li>
                                            <Link to='/brand-login'>Partner Login</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-1 col-sm-1 col-md-3'>
                                    <img className="mobilenone" src={logo} alt='Aruzy'/>
                                    <div className="d-flex footersocial">
                                        <a target="_blank" rel="noreferrer" href={configData.company.social.facebook}>
                                            <StaticImage src='../images/newimage/fb.png' alt={'Aruzy Facebook'}/>
                                        </a>
                                        <a target="_blank" rel="noreferrer" href={configData.company.social.instagram}>
                                            <StaticImage src='../images/newimage/insta.png' alt={'Aruzy Instagram'}/>
                                        </a>
                                        <a target="_blank" rel="noreferrer" href={configData.company.social.twitter}>
                                            <StaticImage src='../images/newimage/twitter.png' alt={'Aruzy Twitter'}/>
                                        </a>
                                        <a target="_blank" rel="noreferrer" href={configData.company.social.tiktok}>
                                            <StaticImage src='../images/newimage/tiktok.png' alt={'Aruzy TikTok'}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <p
                                style={{
                                    color: 'white',
                                    textAlign: 'center',
                                    paddingTop: '10px',
                                    margin: '0'
                                }}
                            >
                                {/*Powered by Cleartwo © Aruzy 2021 | All Rights Reserved*/}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
