// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"
	
const pageLoaded = function(){
	
	
	if(isBrowser)
	{
		window.onscroll = function() {stickyHeader()};

		var header = document.getElementById("headerContainer");
		var sticky = header.offsetTop;
	}
	
	function stickyHeader() {
	  if (isBrowser && window.pageYOffset > sticky) {
		header.classList.add("sticky");
	  } else {
		header.classList.remove("sticky");
	  }
	}
}

export default pageLoaded;




