import React, {useEffect, useState} from 'react'
import logo from '../images/logo.png'
import logowhite from '../images/logo_white.png'
import {Link} from 'gatsby'
import {saerchpath} from '../services/auth'
import pageLoaded from '../js/app.js'
import {navigate} from 'gatsby'
import CookieConsent from "react-cookie-consent";

const Header = ({data}) => {
    var schemaOrgJSONLD = {
        '@context': 'https://schema.org/', '@type': 'BreadcrumbList', itemListElement: [{
            '@type': 'ListItem',
            position: 1,
            name: 'Restaurants',
            item: 'https://aruzy.co.uk/category/restaurants'
        }, {
            '@type': 'ListItem',
            position: 2,
            name: 'Experiences',
            item: 'https://aruzy.co.uk/category/activities'
        }, {
            '@type': 'ListItem', position: 3, name: 'Login', item: 'https://aruzy.co.uk/login'
        }, {
            '@type': 'ListItem', position: 4, name: 'FAQ’s', item: 'https://aruzy.co.uk/faq/'
        }]
    }
    var schemaOrgJSONLD1 = {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: 'Aruzy',
        alternateName: 'Aruzy',
        url: 'https://aruzy.co.uk/',
        logo: 'https://aruzy.co.uk/static/logo_white-1ceca3325a2c08da963d9a16af30c268.png',
        sameAs: ['https://www.facebook.com/Aruzy-UK-113596747627626', 'https://mobile.twitter.com/aruzyuk', 'https://www.instagram.com/aruzyuk/']
    }
    var schemaOrgJSONLD2 = {
        '@context': 'https://schema.org/',
        '@type': 'WebSite',
        name: 'Aruzy',
        url: 'https://aruzy.co.uk/',
        potentialAction: {
            '@type': 'SearchAction',
            target: 'https://aruzy.co.uk/search?q=/{search_term_string}',
            'query-input': 'required name=search_term_string'
        }
    }

    const isBrowser = typeof window !== 'undefined'
    const loggedIn = isBrowser ? localStorage.getItem('aruzyuser') !== null : false
    const [searchState, setSearchState] = useState({
        search: ''
    })

    const [mobileSearch, setMobileSearch] = useState(false)
    const [mobileMenu, setMobileMenu] = useState(false)

    function redirectTosearch() {
        localStorage.setItem('search', searchState.search)
        navigate(saerchpath(encodeURI('/')))
    }

    const toggleMobileMenu = () => {
        setMobileMenu(!mobileMenu)
    }

    useEffect(() => {
        pageLoaded()
    }, [])
    // show stickyLogo only when nav hits the top
    // const [stickyLogo, setStickyLogo] = useState(false)
    // const handleScroll = () => {
    //     if (window.scrollY > 160 && window.innerWidth < 600) {
    //         setStickyLogo(true)
    //     } else if (window.scrollY > 100 && window.innerWidth > 600) {
    //         setStickyLogo(true)
    //     } else {
    //         setStickyLogo(false)
    //     }
    // }
    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll)
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll)
    //     }
    // }, [])
    return (<>
        <header>
            <script type="application/ld+json">
                {JSON.stringify(schemaOrgJSONLD)}
            </script>
            <script type="application/ld+json">
                {JSON.stringify(schemaOrgJSONLD1)}
            </script>
            <script type="application/ld+json">
                {JSON.stringify(schemaOrgJSONLD2)}
            </script>
            <div id='headerContainer'>
                <div className='container-fluid' style={{paddingTop: '10px', background: '#fff', paddingBottom: '10px'}}>
                    <div className=' paddingbanner mobilenav'>
                        <div className='row align-items-center'>
                            <div className='col-sm-12 col-md-4 mobilenone col-xs-12'>
                                <a href='/'>
                                    <img
                                        height='50px'
                                        className='logo-image center-block img-responsive'
                                        src={logo}
                                        alt='Aruzy'
                                    />
                                </a>
                            </div>
                            <div className='col-md-4 col-xs-12'>
                                <form role='search' onSubmit={(e) => {
                                    e.preventDefault();
                                    redirectTosearch(searchState)
                                }} method='get' className='clearfix headerSearch'>
                                    <div className='input-group'>
                                        <input
                                            type='text'
                                            autoComplete='off'
                                            onChange={(e) => setSearchState({
                                                ...searchState, search: e.target.value
                                            })}
                                            style={{backgroundColor: '#fff'}}
                                            className='form-control left input-search search'
                                            name='q'
                                            placeholder='What are you looking for?'
                                        />
                                        <button className='input-group-text searchbtn customRounded'>
                                            <i className='fa fa-search'/>
                                        </button>
                                        <div className='search-box-wrapper borders hidden-div search-0 search' id='search-0' style={{display: 'none'}}>
                                            <div id='search_row-0' className='position-relative hidden-div search-row search_row-0'>
                                                <div className='product_image_container'>
                                                    <img className='product_image' alt='My Product'/>
                                                </div>
                                                <div id='product_name' className='product_name_container h4'>
                                                    <p/>
                                                </div>
                                                <div id='product_price' className='product_price_container h4'>
                                                    <p style={{textAlign: 'right!important'}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='col-sm-12 col-md-4 col-xs-12 desktopView'>
                                <table className="navebartopicon mobilepadding" align='right' border={0} cellPadding={1} cellSpacing={1} style={{width: '100%'}}>
                                    <tbody>
                                    <tr>
                                        <td style={{width: '25%'}}>
                                            <div className='text-center'>
                                        <span style={{fontSize: '14px'}}>
                                          <Link to={loggedIn ? '/account' : '/login'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22.423" height="22.423" viewBox="0 0 28.425 28.423"><g transform="translate(541.399 548.867)"><path
                                                d="M186.005,108.675a14.179,14.179,0,0,0-6.579-11.73,10.669,10.669,0,0,1-15.243,0,14.178,14.178,0,0,0-6.582,11.961,1.238,1.238,0,0,0,1.237,1.239h25.931a.034.034,0,0,1,.02,0,1.238,1.238,0,0,0,1.237-1.239,1.175,1.175,0,0,0-.021-.231"
                                                transform="translate(-699 -630.589)" fill="#ee316a"/><path d="M179.532,89.45a7.729,7.729,0,1,1-7.729-7.728,7.729,7.729,0,0,1,7.729,7.728"
                                                                                                           transform="translate(-699 -630.589)" fill="#ee316a"/></g></svg>
                                            <br/>
                                            <span>
                                              {loggedIn ? 'My Account' : 'Members'}
                                            </span>
                                          </Link>
                                        </span>
                                            </div>
                                        </td>
                                        <td className='text-center' style={{width: '25%'}}>
                                            <div style={{textAlign: 'center'}}>
                                            <span style={{fontSize: '14px'}}><Link
                                                to='/packages'><svg xmlns="http://www.w3.org/2000/svg" width="22.423" height="22.423" viewBox="0 0 27.389 28.423"><g
                                                transform="translate(270.667 95.183)"><path
                                                d="M567.977,395.664h-5.692a3.911,3.911,0,0,0-6.038-4.908,4.029,4.029,0,0,0-.841,1.252c-.113.255-.244.625-.378,1.042-.136-.417-.267-.79-.379-1.046a4.034,4.034,0,0,0-.842-1.248,3.911,3.911,0,0,0-6.039,4.908h-5.691a.746.746,0,0,0-.745.748v7.471a.745.745,0,0,0,.745.745h10.072v-5.8h5.757v5.8h10.07a.745.745,0,0,0,.745-.745v-7.471a.746.746,0,0,0-.745-.748m-17.691,0-.7-.692a2.051,2.051,0,0,1,2.9-2.9,2.266,2.266,0,0,1,.452.677c.237.542.633,1.893.919,2.916Zm10.176-.692-.694.692h-3.574c.286-1.023.68-2.373.919-2.914a2.239,2.239,0,0,1,.45-.679,2.051,2.051,0,0,1,2.9,2.9"
                                                transform="translate(-812 -484.795)" fill="#ee316a"/><path d="M560.655,420.953h8.852a.744.744,0,0,0,.745-.745v-10.08h-9.6Z"
                                                                                                           transform="translate(-814.748 -487.713)" fill="#ee316a"/><path
                                                d="M542.756,410.129v10.08a.745.745,0,0,0,.745.745h8.851V410.129Z" transform="translate(-812.202 -487.713)"
                                                fill="#ee316a"/></g></svg><br/>{' '}<span>Packages</span></Link></span>
                                            </div>
                                        </td>
                                        <td style={{width: '25%'}}>
                                            <div className='text-center'>
                                            <span style={{fontSize: '14px'}}>
                                              <Link to='/faq'>
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     width="26.02" height="23.51"
                                                     viewBox="0 0 32.02 27.51"><g
                                                    transform="translate(-1340.895 215.699)"><path
                                                    d="M16.906,235.1c-8.828,0-16.011,6.17-16.011,13.756A12.056,12.056,0,0,0,2.371,254.6v6.773a1.235,1.235,0,0,0,1.237,1.237,1.26,1.26,0,0,0,.194-.014l6.848-1.087a18.172,18.172,0,0,0,6.256,1.1c8.827,0,16.009-6.17,16.009-13.754S25.733,235.1,16.906,235.1"
                                                    transform="translate(1340 -450.794)"
                                                    fill="#ee316a"/><text
                                                    transform="translate(1352 -195)" fill="#fff"><tspan
                                                    x="0" y="0">?</tspan></text></g></svg>
                                                <br/> <span>FAQ's</span>
                                              </Link>
                                            </span>
                                            </div>
                                        </td>

                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='row mt-4' id='mobileSearch' style={mobileSearch ? {display: 'block'} : {display: 'none'}}>
                            <div className='col-12'>
                                <form
                                    role='search'
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        redirectTosearch(searchState)
                                    }}
                                    method='get'
                                    className='clearfix headerSearch'
                                >
                                    <div className='input-group'>
                                        <input
                                            type='text'
                                            autoComplete='off'
                                            onChange={(e) => setSearchState({
                                                ...searchState, search: e.target.value
                                            })}
                                            style={{backgroundColor: '#fff'}}
                                            className='form-control left input-search search'
                                            name='q'
                                            placeholder='Start Searching...'
                                        />
                                        <button className='input-group-text customRounded'>
                                            <i className='fa fa-search'/>
                                        </button>
                                        <div className='search-box-wrapper borders hidden-div search-0 search' id='search-0' style={{display: 'none'}}>
                                            <div id='search_row-0' className='position-relative hidden-div search-row search_row-0'>
                                                <div className='product_image_container'>
                                                    <img className='product_image' alt='My Product'/>
                                                </div>
                                                <div id='product_name' className='product_name_container h4'>
                                                    <p/>
                                                </div>
                                                <div id='product_price' className='product_price_container h4'>
                                                    <p style={{textAlign: 'right!important'}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-fluid' style={{background: '#1c1d2d'}}>
                    <nav className='navbar navbar-expand-lg navbar-dark bg-dark'>
                        <div className='container-fluid'>
                            <a className="mobiledisplay desktopnone " href='/'>
                                <img height='30px' className='logo-image center-block img-responsive' src={logowhite} alt='Aruzy'/>
                            </a>
                            <div className={'mobileNavLinks mobileView'}>
                                <Link to={loggedIn ? '/account' : '/login'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22.423" height="22.423" viewBox="0 0 28.425 28.423">
                                        <g transform="translate(541.399 548.867)">
                                            <path
                                                d="M186.005,108.675a14.179,14.179,0,0,0-6.579-11.73,10.669,10.669,0,0,1-15.243,0,14.178,14.178,0,0,0-6.582,11.961,1.238,1.238,0,0,0,1.237,1.239h25.931a.034.034,0,0,1,.02,0,1.238,1.238,0,0,0,1.237-1.239,1.175,1.175,0,0,0-.021-.231"
                                                transform="translate(-699 -630.589)" fill="#ee316a"/>
                                            <path d="M179.532,89.45a7.729,7.729,0,1,1-7.729-7.728,7.729,7.729,0,0,1,7.729,7.728"
                                                  transform="translate(-699 -630.589)" fill="#ee316a"/>
                                        </g>
                                    </svg>
                                </Link><Link
                                to='/packages'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22.423" height="22.423" viewBox="0 0 27.389 28.423">
                                    <g
                                        transform="translate(270.667 95.183)">
                                        <path
                                            d="M567.977,395.664h-5.692a3.911,3.911,0,0,0-6.038-4.908,4.029,4.029,0,0,0-.841,1.252c-.113.255-.244.625-.378,1.042-.136-.417-.267-.79-.379-1.046a4.034,4.034,0,0,0-.842-1.248,3.911,3.911,0,0,0-6.039,4.908h-5.691a.746.746,0,0,0-.745.748v7.471a.745.745,0,0,0,.745.745h10.072v-5.8h5.757v5.8h10.07a.745.745,0,0,0,.745-.745v-7.471a.746.746,0,0,0-.745-.748m-17.691,0-.7-.692a2.051,2.051,0,0,1,2.9-2.9,2.266,2.266,0,0,1,.452.677c.237.542.633,1.893.919,2.916Zm10.176-.692-.694.692h-3.574c.286-1.023.68-2.373.919-2.914a2.239,2.239,0,0,1,.45-.679,2.051,2.051,0,0,1,2.9,2.9"
                                            transform="translate(-812 -484.795)" fill="#ee316a"/>
                                        <path d="M560.655,420.953h8.852a.744.744,0,0,0,.745-.745v-10.08h-9.6Z"
                                              transform="translate(-814.748 -487.713)" fill="#ee316a"/>
                                        <path
                                            d="M542.756,410.129v10.08a.745.745,0,0,0,.745.745h8.851V410.129Z" transform="translate(-812.202 -487.713)"
                                            fill="#ee316a"/>
                                    </g>
                                </svg>
                                <br/></Link>
                                <Link to='/faq'>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         width="26.02" height="23.51"
                                         viewBox="0 0 32.02 27.51">
                                        <g
                                            transform="translate(-1340.895 215.699)">
                                            <path
                                                d="M16.906,235.1c-8.828,0-16.011,6.17-16.011,13.756A12.056,12.056,0,0,0,2.371,254.6v6.773a1.235,1.235,0,0,0,1.237,1.237,1.26,1.26,0,0,0,.194-.014l6.848-1.087a18.172,18.172,0,0,0,6.256,1.1c8.827,0,16.009-6.17,16.009-13.754S25.733,235.1,16.906,235.1"
                                                transform="translate(1340 -450.794)"
                                                fill="#ee316a"/>
                                            <text
                                                transform="translate(1352 -195)" fill="#fff">
                                                <tspan
                                                    x="0" y="0">?
                                                </tspan>
                                            </text>
                                        </g>
                                    </svg>
                                </Link>
                            </div>
                            <button
                                className='navbar-toggler'
                                type='button'
                                data-toggle='collapse'
                                data-target='#navbarSupportedContent'
                                aria-controls='navbarSupportedContent'
                                aria-expanded='false'
                                aria-label='Toggle navigation'
                                onClick={() => {
                                    toggleMobileMenu()
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.2"
                                     viewBox="0 0 40 28" width="33" height="28" style={{fill: 'none', stroke: '#ffffff', strokeLinecap: 'round', strokeWidth: '3'}}>
                                    <path id="Path 11" className="s0" d="m1.9 1.8h36.1"/>
                                    <path id="Path 12" className="s0" d="m1.9 13.8h36.1"/>
                                    <path id="Path 13" className="s0" d="m1.9 25.9h36.1"/>
                                </svg>
                            </button>

                            <div className={`collapse navbar-collapse ${mobileMenu ? 'mobilenavbar' : ''}`} id='navbarSupportedContent'>
                                <ul className={`navbar-nav mr-auto ${mobileMenu ? 'show' : ''}`}>
                                    <li className='nav-item'>
                                        <Link to='/category/restaurants' activeClassName="active" className='nav-link'>Restaurants</Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link to='/category/experiences' activeClassName="active" className='nav-link'>Experiences</Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link to='/category/retail' activeClassName="active" className='nav-link'>Retail</Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link to='/category/services' activeClassName="active" className='nav-link'>Services</Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link to='/category/events' activeClassName="active" className='nav-link'>Events</Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link to='/about-us' activeClassName="active" className='nav-link'>About Us</Link>
                                    </li>
                                    {/*<li className='nav-item'>*/}
                                    {/*<Link to='/brand' className='nav-link'>*/}
                                    {/*Brand*/}
                                    {/*</Link>*/}
                                    {/*</li>*/}
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
        <CookieConsent
            location="bottom"
            buttonText="I Understand"
            cookieName="cookiesUsingPolicy"
            style={{background: "#212529", opacity: 0.9, alignItems: "center"}}
            buttonStyle={{color: "#ffffff", background: "#ff3c7c", borderRadius: "0.3rem", fontSize: "13px", fontWeight: 600}} expires={150}>
            This website uses cookies to enhance the user experience.
        </CookieConsent>
    </>)
}

export default Header
